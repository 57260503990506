/** @format */
import buildUrl from 'build-url';

const baseUrl = 'https://doonkee.com/';

// const baseUrl = 'https://kien.chiaseacc.com/'

const apiUrl = baseUrl + 'api/';

const apiBuilder = (module: string): string => {
	return buildUrl(apiUrl, {
		path: module,
	});
};

const fanpageUrl = 'https://m.me/doonkeecom/';

const facebookUrl = 'https://m.me/100010761271946';

const zaloUrl = 'http://zalo.me/0911371139';

const darkTheme = true;

const bannerImage = baseUrl + 'banner.jpg';

const linkImage = baseUrl + 'link.txt';

const disableConsole = process.env.NODE_ENV == 'development' ? false : true;

export { apiBuilder, fanpageUrl, facebookUrl, zaloUrl, darkTheme, disableConsole, bannerImage, linkImage };
