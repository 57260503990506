/** @format */

import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import ZaloIcon from '../components/ZaloIcon.vue';
import { darkTheme } from '../config';
import vi from './vuetify-vi';

Vue.use(Vuetify);

export default new Vuetify({
	theme: { dark: darkTheme },
	icons: {
		values: {
			ZaloIcon: {
				component: ZaloIcon,
			},
		},
	},
	lang: {
		locales: { vi },
		current: 'vi',
	},
});
