










import { Vue, Component } from 'vue-property-decorator';
import { disableConsole } from './config';

@Component({})
export default class App extends Vue {
	created() {
		if (disableConsole) {
			document.oncontextmenu = () => {
				return false;
			};

			document.onkeydown = function(event) {
				event = event || window.event;

				if (event.keyCode == 123) {
					return false;
				}

				if (event.ctrlKey && event.shiftKey && event.keyCode == 73) {
					return false;
				}
				//"J" key
				if (event.ctrlKey && event.shiftKey && event.keyCode == 74) {
					return false;
				}
				//"S" key
				if (event.ctrlKey && event.keyCode == 83) {
					return false;
				}
				//"U" key
				if (event.ctrlKey && event.keyCode == 85) {
					return false;
				}
			};
		}
	}
}
