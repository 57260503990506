<!-- @format -->

<template>
	<div>
		<AppBar class="mb-16" />

		<Marquee class="mb-10" />

		<NotiBanner />

		<v-main id="content" class="mb-3">
			<router-view></router-view>
		</v-main>

		<ContactBar />

		<Footer class="mt-5" />
	</div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import AppBar from '../components/AppBar.vue';
import Footer from '../components/Footer.vue';
import Marquee from '../components/Marquee.vue';
import NotiBanner from '../components/NotiBanner.vue';
import ContactBar from '../components/ContactBar.vue';

@Component({
	components: {
		AppBar,
		Footer,
		Marquee,
		NotiBanner,
		ContactBar,
	},
})
export default class Index extends Vue {}
</script>

<style lang="scss">
.noti {
	padding-top: 2vh;
}

#content {
	padding-top: 3vh;
}
</style>
