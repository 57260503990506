














import { Vue, Component } from 'vue-property-decorator';
import { apiBuilder } from '../config';
import axios from 'axios';

@Component
export default class Marquee extends Vue {
	private marqueeText = '';

	private getNotice() {
		const apiUrl = apiBuilder('support/adminnotice.php');

		axios
			.get(apiUrl)
			.then((res) => {
				// console.log(res.data)

				const error = res.data.error;

				if (error != '950') {
					return this.$swal({
						icon: 'error',
						title: 'Lỗi !',
						text: 'Server Error ! Please Reload',
					});
				}

				const data = res.data.data;

				const marqueeText = data.map((i: any) => {
					return `<span>${i.content}</span>`;
				});

				this.marqueeText = marqueeText.join('');
			})
			.catch((e) => {
				this.$swal({
					icon: 'error',
					title: 'Lỗi !',
					text: e,
				});
			});
	}

	created() {
		this.getNotice();
	}
}
