



























































import { Vue, Component } from 'vue-property-decorator';
import axios from 'axios';
import { apiBuilder } from '../../config';
import { namespace } from 'vuex-class';
import { debounce } from 'lodash';

const productStore = namespace('product');

@Component
export default class Products extends Vue {
	@productStore.Mutation
	public setProduct!: (payload: any) => void; //eslint-disable-line

	@productStore.State
	private productList!: Array<object>;

	private productsList: Array<object> = [];

	private loading = true;

	private searchBox = '';

	get handleInputDebounced() {
		return debounce(this.searchProduct, 500);
	}

	public getProducts(): void {
		this.loading = true;

		const api = apiBuilder('product/listallproducttype.php');

		axios
			.get(api)
			.then((res) => {
				const resp = res.data;

				if (resp.code === '742') {
					this.$swal({
						icon: 'error',
						title: 'Lỗi !',
						text: 'Server Error',
					});
				} else {
					this.productsList = resp.data;
					this.setProduct(resp.data);
				}

				setTimeout(() => {
					this.loading = false;
				}, 200);
			})
			.catch((e) => {
				this.$swal({
					icon: 'error',
					title: 'Lỗi !',
					text: e,
				});
			});
	}

	private redirectToProduct(id: string): void {
		this.$router.push(this.productUrl(id));
	}

	public productUrl(id: string): string {
		return '/product/' + id;
	}

	public searchProduct() {
		const searchValue = this.searchBox.trim().toLowerCase();

		if (searchValue) {
			const api = apiBuilder('product/searchproduct.php');

			axios
				.get(api, {
					params: {
						searchkey: searchValue,
					},
				})
				.then((res) => {
					const resp = res.data;

					if (resp.error != 720) {
						// this.$swal({
						// 	icon: 'error',
						// 	title: 'Lỗi !',
						// 	text: 'Server Error',
						// });

						console.log(resp.description);
					} else {
						this.productsList = resp.data;
					}
				})
				.catch((e) => {
					console.log(e);
				});
		} else {
			this.productsList = this.productList;
		}
	}

	mounted() {
		if (this.productList.length > 0) {
			this.productsList = this.productList;
			this.loading = false;
		} else {
			this.getProducts();
		}
	}
}
