/** @format */

import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import Index from '../views/Index.vue';
import Home from '../views/Home/Home.vue';

import store from '../store/index';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
	{
		path: '/',
		component: Index,
		children: [
			{
				path: '',
				component: Home,
				name: 'Home',
			},
			// {
			// 	path: '/netflix',
			// 	name: 'Netflix',
			// 	component: () => import(/* webpackChunkName: "netflix" */ '../views/Home/Netflix.vue'),
			// },
			{
				path: '/product/:id',
				name: 'Product',
				component: () => import(/* webpackChunkName: "product" */ '../views/Home/ProductDetail.vue'),
			},
			{
				path: '/profile',
				component: () => import(/* webpackChunkName: "profile" */ '../views/Auth/Profile.vue'),
				meta: {
					requiresAuth: true,
				},
				children: [
					{
						name: 'Profile',
						path: '',
						component: () => import(/* webpackChunkName: "profile-perrson" */ '../views/Profile/Person.vue'),
					},
					{
						name: 'Deposit',
						path: '/profile/deposit',
						component: () => import(/* webpackChunkName: "profile-deposit" */ '../views/Profile/Deposit.vue'),
					},
					{
						name: 'OrderHistory',
						path: '/profile/orders',
						component: () => import(/* webpackChunkName: "profile-orders" */ '../views/Profile/OrderHistory.vue'),
					},
					{
						name: 'TransactionHistory',
						path: '/profile/transactions',
						component: () => import(/* webpackChunkName: "profile-transactions" */ '../views/Profile/TransactionHistory.vue'),
					},
					{
						name: 'ChangePassword',
						path: '/profile/changePassword',
						component: () => import(/* webpackChunkName: "profile-changePassword" */ '../views/Profile/ChangePassword.vue'),
					},
					{
						name: 'SupportCase',
						path: '/profile/support',
						component: () => import(/* webpackChunkName: "profile-SupportCase" */ '../views/Profile/SupportCase.vue'),
					},
				],
			},
			{
				path: '/policy',
				name: 'Policy',
				component: () => import(/* webpackChunkName: "policy" */ '../views/Home/Policy.vue'),
			},
			{
				path: '/guide',
				name: 'Guild',
				component: () => import(/* webpackChunkName: "guide" */ '../views/Home/Guide.vue'),
			},
			{
				path: '/contact',
				name: 'Contact',
				component: () => import(/* webpackChunkName: "contact" */ '../views/Home/Contact.vue'),
			},
		],
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import(/* webpackChunkName: "login" */ '../views/Auth/Login.vue'),
	},
	{
		path: '/register',
		name: 'Register',
		component: () => import(/* webpackChunkName: "register" */ '../views/Auth/Register.vue'),
	},
	{
		path: '/forgot',
		name: 'ForgotPassword',
		component: () => import(/* webpackChunkName: "forgot-password" */ '../views/Auth/ForgotPassword.vue'),
	},
	{
		path: '/resetpassword',
		name: 'ResetPassword',
		component: () => import(/* webpackChunkName: "reset-password" */ '../views/Auth/ResetPassword.vue'),
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	const user = store.state.auth.isLogined;

	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (!user) {
			next({ name: 'Login' });
		} else {
			next();
		}
	} else {
		next();
	}
});

export default router;
