/** @format */

import { VuexModule, Module, Mutation } from 'vuex-module-decorators';

@Module({ namespaced: true, name: 'config' })
class Config extends VuexModule {
	public darkTheme = true;

	@Mutation
	public setDarkTheme(payload: boolean): void {
		this.darkTheme = payload;
	}
}

export default Config;
