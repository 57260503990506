






























































































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { apiBuilder } from '../config';
import axios from 'axios';

import { ToggleButton } from 'vue-js-toggle-button';
Vue.component('ToggleButton', ToggleButton);

const auth = namespace('auth');
const config = namespace('config');

@Component
export default class AppBar extends Vue {
	private mobileDrawer = false;

	@auth.State
	public username!: string;
	@auth.State
	public isLogined!: boolean;
	@auth.State
	public token!: string;

	@config.State
	public darkTheme!: boolean;

	private userProfile = {};

	get checkLogin(): boolean {
		if (this.isLogined && this.username !== '') {
			this.getUserProfile();
			return true;
		} else {
			return false;
		}
	}

	@auth.Mutation
	public removeUser!: () => void;

	@config.Mutation
	public setDarkTheme!: (payload: boolean) => void;

	public logout() {
		this.$swal({
			icon: 'question',
			title: 'Bạn có muốn đăng xuất!',
			showConfirmButton: true,
			showCancelButton: true,
			confirmButtonText: 'Có',
			cancelButtonText: 'Không',
			reverseButtons: true,
		}).then((result) => {
			if (result.isConfirmed) {
				const apiUrl = apiBuilder('/account/logout.php');

				const param = {
					params: {
						token: this.token,
					},
				};

				this.removeUser();

				axios.post(apiUrl, null, param);

				this.$router.push({ name: 'Home' });

				return this.$swal({
					icon: 'success',
					title: 'Thành Công!',
					text: 'Đăng Xuất Thành Công',
				});
			}
		});
	}

	private getUserProfile(): void {
		const api = apiBuilder('profile/profileinfo.php');

		const param = {
			params: {
				token: this.token || 'a',
			},
		};

		axios
			.post(api, null, param)
			.then((res) => {
				// console.log(res.data)
				const error = res.data.error;

				if (error == '302') {
					sessionStorage.setItem('redirectPath', window.location.pathname);

					this.$router.replace({
						name: 'Login',
					});
				} else if (error != '301') {
					return this.$swal({
						icon: 'error',
						title: 'Lỗi !',
						text: 'Server Error! Plesae Reload',
					});
				}

				this.userProfile = res.data;
			})
			.catch((e) => {
				return this.$swal({
					icon: 'error',
					title: 'Lỗi !',
					text: e,
				});
			});
	}

	public toggleDark(): void {
		// this.$vuetify.theme.dark = !this.$vuetify.theme.dark;

		if (this.$vuetify.theme.dark) {
			this.$vuetify.theme.dark = false;
			this.setDarkTheme(false);
		} else if (!this.$vuetify.theme.dark) {
			this.$vuetify.theme.dark = true;
			this.setDarkTheme(true);
		}
	}

	created() {
		this.$vuetify.theme.dark = this.darkTheme;
	}
}
