/** @format */

import { VuexModule, Module, Mutation } from 'vuex-module-decorators';

@Module({ namespaced: true, name: 'product' })
class Products extends VuexModule {
	public productList: Array<object> = [];

	@Mutation
	public setProduct(payload: Array<object>): void {
		this.productList = payload;
	}
}

export default Products;
