/** @format */

import { VuexModule, Module, Mutation } from 'vuex-module-decorators'

interface AuthState {
	token: string | number
	username: string
}

@Module({ namespaced: true, name: 'auth' })
class Auth extends VuexModule {
	public token: string | number = ''
	public username = ''
	public isLogined = false

	@Mutation
	public setUser(payload: AuthState): void {
		this.token = payload.token
		this.username = payload.username
		this.isLogined = true
	}

	@Mutation
	public removeUser(): void {
		this.token = ''
		this.username = ''
		this.isLogined = false
	}
}

export default Auth
