/** @format */

import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import Auth from './auth';
import Product from './product';
import Config from './config';

const dataState = createPersistedState({
	paths: ['auth', 'config'],
});

Vue.use(Vuex);

export default new Vuex.Store({
	state: {},
	mutations: {},
	actions: {},
	modules: {
		auth: Auth,
		product: Product,
		config: Config,
	},
	plugins: [dataState],
});
