<!-- @format -->

<template>
	<div class="contactBtn">
		<ul class="giuseart-pc-contact-bar">
			<li class="facebook">
				<a :href="fanpageUrl" target="_blank" rel="nofollow"></a>
			</li>
			<li class="facebook person">
				<a :href="facebookUrl" target="_blank" rel="nofollow"></a>
			</li>
			<li class="zalo">
				<a :href="zaloUrl" target="_blank" rel="nofollow"></a>
			</li>
		</ul>

		<ul class="giuseart-mobile-contact-bar">
			<li class="facebook">
				<a :href="facebookUrl" target="_blank" rel="nofollow"></a>
			</li>
			<li class="facebook person">
				<a :href="facebookUrl" target="_blank" rel="nofollow"></a>
			</li>
			<li class="zalo">
				<a :href="zaloUrl" target="_blank" rel="nofollow"></a>
			</li>
		</ul>
	</div>
</template>

<script>
import { facebookUrl, zaloUrl, fanpageUrl } from '../config';

export default {
	data: () => ({
		facebookUrl,
		zaloUrl,
		fanpageUrl,
	}),
};
</script>

<style lang="scss" scoped>
.giuseart-mobile-contact-bar {
	display: none;
}
.giuseart-pc-contact-bar {
	bottom: 4rem;
	position: fixed;
	z-index: 998;
	margin-bottom: 0;
}
.giuseart-pc-contact-bar li {
	width: 44px;
	height: 46px;
	overflow: hidden;
	margin-bottom: 0;
	list-style: none;
}
.giuseart-pc-contact-bar li.facebook {
	margin-bottom: 8px;
	background: url('../assets/img/fb.png');
	background-repeat: no-repeat;
}
.giuseart-pc-contact-bar li.facebook.person {
	margin-bottom: 8px;
	background: url('../assets/img/facebook.png');
	background-repeat: no-repeat;
}
.giuseart-pc-contact-bar li.zalo {
	background: url('../assets/img/zl.png');
	background-repeat: no-repeat;
}
.giuseart-pc-contact-bar li a {
	display: block;
	width: 44px;
	height: 44px;
}

@media only screen and (max-width: 499px) {
	.giuseart-pc-contact-bar {
		display: none;
	}
	.giuseart-mobile-contact-bar {
		left: 10px;
		bottom: 10px;
		position: fixed;
		z-index: 998;
		margin-bottom: 0;
		display: block !important;
	}
	.giuseart-mobile-contact-bar li {
		// display: inline-block;
		float: left;
		margin-right: 7px;
		list-style: none;
	}
	.giuseart-mobile-contact-bar li.facebook {
		margin-bottom: 8px;
		background: url('../assets/img/fb.png');
		background-repeat: no-repeat;
	}
	.giuseart-mobile-contact-bar li.facebook.person {
		margin-bottom: 8px;
		background: url('../assets/img/facebook.png');
		background-repeat: no-repeat;
	}
	.giuseart-mobile-contact-bar li.zalo {
		background: url('../assets/img/zl.png');
		background-repeat: no-repeat;
	}
	.giuseart-mobile-contact-bar li a {
		display: block;
		width: 44px;
		height: 44px;
	}
	.giuseart-mobile-contact-bar li.hotline {
		background: url('../assets/img/p2.gif');
		background-repeat: no-repeat;
		background-size: 44px;
	}
}
</style>
