
























import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const config = namespace('config');

@Component
export default class Footer extends Vue {
	@config.State
	public darkTheme!: boolean;
}
