<!-- @format -->

<template>
	<v-overlay opacity="0.3" v-model="show" @click.native="toggleNoti">
		<div class="noti">
			<v-card class="notiBanner mx-3" :class="{ 'theme-light': !$vuetify.theme.dark }" rounded="lg">
				<v-card-subtitle class="pa-0 px-3 pr-5 py-2 subTitle">
					<v-icon class="closeIcon" alt="Đóng" @click="show = false">mdi-close</v-icon>
				</v-card-subtitle>

				<a :href="link" target="_blank">
					<img class="bannerImg" :src="image" />
				</a>
			</v-card>
		</div>
	</v-overlay>
</template>

<script>
import axios from 'axios';
import { bannerImage, linkImage } from '../config';

export default {
	name: 'NotiBanner',
	data: () => ({
		show: true,
		image: null,
		link: '',
	}),
	methods: {
		async checkImage() {
			try {
				const res = await axios.get(bannerImage, {
					headers: {
						'Content-type': 'image/jpeg',
					},
					responseType: 'blob',
				});

				// console.log(res.data);

				this.image = URL.createObjectURL(res.data);
				this.show = true;
			} catch (e) {
				console.log(e);
				this.show = false;
			}
		},
		async getUrl() {
			try {
				const res = await axios.get(linkImage);

				this.link = res.data;
			} catch (e) {
				console.log(e);
			}
		},
		toggleNoti() {
			this.show = false;
		},
	},
	created() {
		this.checkImage();
		this.getUrl();
	},
};
</script>

<style lang="scss" scoped>
.closeIcon {
	margin-left: 98%;
}

.noti {
	display: flex;
	justify-items: center;
	user-select: none;
	transform: scale(0.9);
}

.theme-light {
	background-color: #fff;

	.subTitle {
		background-color: #f3f3f3;
	}

	.closeIcon {
		color: #000;
	}
}

.bannerImg {
	width: 100%;
	height: auto;
}
</style>
